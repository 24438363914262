import { useState, useEffect, useContext } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebaseConfig from '../Auth/FirebaseConfig';
import AuthContext from '../../store/auth-context';
import classes from './Wallet.module.css';
import LNBits from 'lnbits';


const Balance = () => {
  const authCtx = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [walletDetails, setWalletDetails] = useState(null);


  // Initialize Firebase if not already initialized
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const logoutHandler = () => {
    authCtx.logout();
    // optional: redirect the user
  };

  useEffect(() => {
    const fetchWalletInfo = async (user) => {
      if (user) {
        try {
          const db = firebase.firestore();
          const userDoc = await db.collection('users').doc(user.uid).collection('wallet').doc('wallet1').get();
          if (userDoc.exists) {
            const userData = userDoc.data();
          
            const { wallet } = LNBits({
              adminKey: userData.userKey,
              invoiceReadKey: userData.invoiceKey,
              endpoint: 'https://legend.lnbits.com', //default
            });

            const walletDetailsResponse = await wallet.walletDetails();
            // console.log('Wallet Details:', walletDetailsResponse);
            setWalletDetails(walletDetailsResponse["balance"]);
          } else {
            console.log('User document does not exist');
          }
        } catch (error) {
          console.error('Error fetching wallet info:', error);
        }
      } else {
        console.log('User not logged in');
      }
    };

    const user = firebase.auth().currentUser;
    // console.log('Logged-in User:', user);
    if (user) {
      fetchWalletInfo(user);
    }
  }, [authCtx.isLoggedIn]);


  // const formattedBalance =  .toFixed(8); ca sa obtinem zecimale.
    const formattedBalance = (walletDetails * 0.00000001).toFixed(8);

  
  let balanceStatus = '';
  if (formattedBalance && formattedBalance < 0.00006) {
    balanceStatus = 'Caută bitcoinsperperson';
  } else if (formattedBalance && formattedBalance > 0.00006 && formattedBalance < 0.00018 ) {
    balanceStatus = 'Altă viață, bagi o pizzană, alea, alea.';
  
  }
  else if (formattedBalance && formattedBalance > 0.00018 && formattedBalance < 0.0014 ) {
    balanceStatus = 'Nu-ți mai place zacusca, gata.';
  }
  else if (formattedBalance && formattedBalance > 0.0014 && formattedBalance < 0.0024) {
    balanceStatus = 'Comandant, General, alea ale tale rege!';
  }
  else if (formattedBalance && formattedBalance > 0.0024 && formattedBalance < 0.0031) {
    balanceStatus = 'Dăi o bere colegilor.';
  }
  else if (formattedBalance && formattedBalance > 0.0031&& formattedBalance < 0.0037) {
    balanceStatus = 'Câinii latră, leul rage. Miaaau!';
  }
  else if (formattedBalance && formattedBalance > 0.0037 && formattedBalance < 0.0043) {
    balanceStatus = 'Capul sus, că doar tu știi cât ai tras.';
  }
  else if (formattedBalance && formattedBalance > 0.0043 && formattedBalance < 0.0049) {
    balanceStatus = 'A intrat bursa, este?';
  }
  else if (formattedBalance && formattedBalance > 0.0049 && formattedBalance < 0.0061) {
    balanceStatus = 'Nu vezi că n-am bani?';
  }
  else if (formattedBalance && formattedBalance > 0.0061 && formattedBalance < 0.0067) {
    balanceStatus = 'Altă excitație.';
  }
  else if (formattedBalance && formattedBalance > 0.0067 && formattedBalance < 0.0092) {
    balanceStatus = 'Parcă ar intra o pizzană..';
  }
  else if (formattedBalance && formattedBalance > 0.0092 && formattedBalance < 0.0098) {
    balanceStatus = 'Alooo, mooni coliing!!';
  }
  else if (formattedBalance && formattedBalance > 0.0098) {
    balanceStatus = '';
  }
  else {
    balanceStatus = 'Your balance is in the normal range.';
  }


  return (
    <div>
      <div className = {classes.balanceNumbers} >
        
      {formattedBalance}
      </div>
      <div className={classes.balanceStatus}>
        {balanceStatus}
      </div>
    </div>
  );
};

export default Balance;
