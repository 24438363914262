import classes from "./BlogElements.module.css";


const NextPreviousButtons = () => {

    const NumberDisplay = () => {
        const urlPath = window.location.pathname;
        const parts = urlPath.split('/');
        const number = parts[parts.length - 1];
    
        return (
    
          <div className={classes.pageNumber}>{number}</div>
    
        );
      }
      const currentArticleNumber = parseInt(window.location.pathname.split('/').pop(), 10);
    
      const goToNextArticle = () => {
        const nextArticleNumber = currentArticleNumber + 1;
        window.location.href = `/blog/article/${nextArticleNumber}`;
      };
    
      const goToPreviousArticle = () => {
        const previousArticleNumber = currentArticleNumber - 1;
    
        if (previousArticleNumber >= 1) {
          window.location.href = `/blog/article/${previousArticleNumber}`;
        } else {
          window.location.href = '/blog';
        }
      };

return(


    <div className={classes.buttonContainer}>
    <button className={classes.pageButton} onClick={goToPreviousArticle}></button>
    <p style={{ marginLeft: "10%", marginRight: "10%" }}><NumberDisplay /></p>
    <button className={classes.pageButton2} onClick={goToNextArticle}></button>
  </div>

);



}


export default NextPreviousButtons;