import classes from '../blog/BlogElements.module.css';
import Back from '../components/Layout/BackButton';
import { Link } from 'react-router-dom';
import NextPreviousButtons from '../blog/NextPreviousButtons';

const Article5 = () => {

 


  return (
    <div className={classes.container}>
      <br></br>
      <header className={classes.header}>
        <div style={{ marginLeft: "-40px" }}> <Back /></div>

        <h1 className={classes.title} style={{ marginTop: "20px" }}>Plățile prin Lightning Network</h1>
      </header>

      <main className={classes.main}>
        <article className={classes.article}>
          <section className={classes.section}>
            <h2 className={classes.subtitle}>Introducere</h2>
            <p className={classes.paragraph}>
</p>
            <br></br>
<br></br>
<h3 className={classes.subtitle}> </h3>
          
              <p className={classes.paragraph}></p>
              <br></br>
              
              <h3 className={classes.subtitle}></h3>

              <p className={classes.paragraph}></p>
              <br></br>

              <h3 className={classes.subtitle}></h3>

              <p className={classes.paragraph}></p>
            <br></br>
      
            <br></br>
            <h4 className={classes.subtitle}>Vrei să înveți despre Bitcoin?</h4>
            <p className={classes.paragraph}> Îți recomandăm să încerci <Link to='/blog' style={{ color: '#87CEEB' }}>celelalte articole</Link> sau chiar <a style={{color: '#87CEEB'}} href='https://ro.wikipedia.org/wiki/Bitcoin'>Wikipedia.</a>
            </p>

            <p className={classes.paragraph}>

              <br />
              <br />
              Noi, Bit Bit, oferim <Link to='/exchange/auth' style={{ color: '#FFA500' }}>structura necesară </Link>
 comercianților pentru a accepta astfel de plăți și ție <Link to='/exchange/auth' style={{ color: '#FFA500' }}>o metodă de a plăti </Link>folosind Bitcoin.
            </p>
          </section>

          <NextPreviousButtons/>

        </article>
       
      </main>
    </div>
  );
}

export default Article5;
