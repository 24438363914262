import Back from '../components/Layout/BackButton';






const Article6 = () => {



    <div>
        <Back/>
    </div>
}


export default Article6;