import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './store/auth-context';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { ReactWebLNFallback } from 'react-webln-fallback-reactstrap';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
    <BrowserRouter>
      <App />
      {/* <ReactWebLNFallback /> */}
    </BrowserRouter>
  </AuthContextProvider>
);
