import { useContext } from 'react';
import { Link } from 'react-router-dom';
import classes from './MainNavigation.module.css';
import '../StartingPage/Custom.css';
import myButtonImage from '../../images/logo bitbit.png';
import AuthContext from '../../store/auth-context';

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <div className={classes.headerContainer}>
    <header className={classes.header}>
      <Link to='/'>
        <div className={classes.logo}>Bit Bit</div>
      </Link>
      {/* MarginTop 10% ca sa fie poza in linie cu Bit Bit */}
       
       <Link to='/'>
        <div className={classes.icon}>
            <img
              src={myButtonImage}
              alt="Logo BitBit"
              style={{
                width: "100px",
                height: "50px",
                marginTop: "10%"
              }}
            />
        </div>
      </Link>
    
      <nav>
        <ul>
          {(!isLoggedIn) && (
            <li>
              <Link to='/exchange/auth'><button className="button" style={{ height: '50px' }}>Register</button></Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to='/profile'><button className="button" style={{ height: '50px' }}>Profile</button></Link>
            </li>
          )}
        </ul>
      </nav>
    </header></div>
  );
};

export default MainNavigation;