import { useState, useRef, useEffect} from 'react';
import { useNavigate} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebaseConfig from './FirebaseConfig';
import classes from './AuthForm.module.css';
import GoogleLoginButton from './GoogleLogin';
import generateHash from '../HashFunction';
import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import SuccessModal from '../Layout/PopUpBox';

const AuthForm = () => {
    firebase.initializeApp(firebaseConfig);
    const db = firebase.firestore();
    const isKYC = 0;

    const navigate = useNavigate();
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);


  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [resetPasswordFormActive, setResetPasswordFormActive] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false); //true pt testing, false pt production
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalText, setSuccessModalText] = useState('');
  const [redirectedAfterRegistration, setRedirectedAfterRegistration] = useState(
    localStorage.getItem('redirectedAfterRegistration') === 'false'
  );
    const generateReferralCode = (uid, email) => {
    const code = uid + email;

    const hashedCode = generateHash(code);
    const shortenedCode = hashedCode.slice(0, 8); // Adjust the length as needed

    return shortenedCode;
  };

  const onRecaptchaChange = (value) => {
    // This function is called when reCAPTCHA is solved
    setIsRecaptchaVerified(true);
  };

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
     setResetPasswordFormActive(false);
  };

  useEffect(() => {
    localStorage.setItem('redirectedAfterRegistration', redirectedAfterRegistration.toString());
  }, [redirectedAfterRegistration]);

  const handleResetPassword = () => {
    if (resetPasswordFormActive) {
      if (!resetPasswordEmail) {
        // Replaced alert with SuccessModal
        setSuccessModalText('Introdu un email valid.');
        setShowSuccessModal(true);
        return;
      }

      firebase
        .auth()
        .sendPasswordResetEmail(resetPasswordEmail)
        .then(() => {
          setSuccessModalText('Linkul pentru a schimba parola a ajuns pe emailul tău cu success.');
          setShowSuccessModal(true);
        })
        .catch((error) => {
          setSuccessModalText(error.message);
          setShowSuccessModal(true);
        });
    } else {
      setResetPasswordFormActive(true);
    }
  };
  const submitHandler = async (event) => {
    event.preventDefault();
  
    if (!resetPasswordFormActive) {
      const enteredEmail = emailInputRef.current?.value;
      const enteredPassword = passwordInputRef.current?.value;
  
      if (!enteredEmail || !enteredPassword) {
        setShowSuccessModal(true);
        setSuccessModalText('Introdu un email sau o parolă validă.');
        return;
      }
  
      if (!isLogin && enteredPassword.length < 6) {
 setShowSuccessModal(true);
        setSuccessModalText("Parola trebuie să conțină mai mult de 6 caractere.");
              return;
      }
      if (!isRecaptchaVerified) {
     setShowSuccessModal(true);
        setSuccessModalText("Te rog rezolvă ReCAPTCHA.");
        return;
      }
      setIsLoading(true);
  
      try {
        if (isLogin || redirectedAfterRegistration) {
          const userCredential = await firebase.auth().signInWithEmailAndPassword(enteredEmail, enteredPassword);
          const user = userCredential.user;
          setIsLoading(false);
      
          // Check if the email is verified
          if (user.emailVerified) {
            const referral = generateReferralCode(user.uid, enteredEmail);
            const userId = user.uid;
            const userData = {
              username: enteredEmail,
              email: enteredEmail,
              balance: 0,
              KYC: isKYC,
              transactions: 0,
              referralCode: referral,
              referralCount: 0,
              progressButtonStatusFromMission: 0,
            };
      
            const missionsData = [
              {
                id: 'mission1',
                data: {
                  name: 'Invită-ți prietenii',
                  progress: 0,
                  text: "Folosește codul de referral din secțiunea profil și vei primi 10 lei în Bitcoin ",
                },
              },
              {
                id: 'mission2',
                data: {
                  name: 'Mergi la o cafea cu prietenii',
                  progress: 0,
                  text: "Cumpără echivalentul a 2 cafele și vei primi 30% reducere la următoarea comandă ",
                },
              },
              {
                id: 'mission3',
                data: {
                  name: 'Apasă butonul',
                  progress: 0,
                  text: "Apasă butonul ",
                },
              },
              {
                id: 'mission4',
                data: {
                  name: 'Test4',
                  progress: 10,
                  text: "Test4",
                },
              },
            ];
      
            const userDocRef = db.collection('users').doc(userId);
            const userDocSnapshot = await userDocRef.get();
      
            // Set user data if the document doesn't exist or email is not verified
            if (!userDocSnapshot.exists || !user.emailVerified) {
              await userDocRef.set(userData);
            }
      
            const missionsCollectionRef = db.collection('users').doc(userId).collection('missions');
            const missionsCollectionSnapshot = await missionsCollectionRef.get();
      
            // Set missions data if the collection doesn't exist or email is not verified
            if (!missionsCollectionSnapshot.exists || !user.emailVerified) {
              for (const mission of missionsData) {
                await missionsCollectionRef.doc(mission.id).set(mission.data);
              }
            }      


            navigate('/wallet', { replace: true });
          } else {
            setIsLoading(false);
            await firebase.auth().signOut(); // Sign out the user to prevent unauthorized access
          }
        } else {
          const userCredential = await firebase
            .auth()
            .createUserWithEmailAndPassword(enteredEmail, enteredPassword);
  
          const user = userCredential.user;
          setIsLoading(false);
  
          


          await user.sendEmailVerification();

          alert(
            'Te-ai înregistrat cu succes. Verifică-ți adresa de mail pentru a te loga.'
          );
            setRedirectedAfterRegistration(true);
          await firebase.auth().signOut(); // Sign out the user after registration
  
          navigate('/exchange/auth', { replace: true });
        }
      } catch (error) {
        setIsLoading(false);
        setShowSuccessModal(true);
        setSuccessModalText(error.message);      }
    }
  };
  
  
  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    setSuccessModalText('');
  };
  
     const handleGoBack = () => {
    setResetPasswordFormActive(false);
  };

    return (
       <section className={classes.auth}>
      <h1>{isLogin || redirectedAfterRegistration ? 'Login' : 'Înregistrare'}</h1>
      <form onSubmit={submitHandler}>
        {resetPasswordFormActive ? (
          <>
            <div className={classes.control}>
              <label htmlFor="resetEmail">Email</label>
              <input
                type="email"
                id="resetEmail"
                required
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
              />
            </div>
            <div className={classes.actions}>
              <button
                type="submit"
                className={classes.resetPasswordSubmit}
                onClick={handleResetPassword}
                disabled={!isRecaptchaVerified}
              >
                Trimite
              </button>
              <button
                type="button"
                className={classes.goBackButton}
                onClick={handleGoBack}
                style={{marginTop:"5%"}}
              >
                Înapoi
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={classes.control}>
          <label htmlFor="email">Email</label>
          <input type="email" id="email" required ref={emailInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor="password">Parolă</label>
          <input type="password" id="password" required ref={passwordInputRef} />
        </div>
            <div className={classes.actions}>
              {!isLoading && (
                <button
                style={{marginBottom:"5%"}}
                disabled={!isRecaptchaVerified}
               
                >
                  {isLogin || redirectedAfterRegistration ? 'Login' : 'Crează contul'}</button>
              )}

              {isLogin || redirectedAfterRegistration && (
              <button
                type="button"
                className={classes.resetPasswordButton}
                onClick={handleResetPassword}
                style={{marginBottom:"5%"}}
              >
                Am uitat parola
              </button>
               )}

              {isLoading && <p>Sending request...</p>}

              <div style={{display:"flex", justifyContent:"center"}}>
              <GoogleLoginButton />
              {/* o sa mai fie butoane, tb pus si spatiu intre ele*/}
              </div>
              <button
                type="button"
                className={classes.toggle}
                onClick={switchAuthModeHandler}
              >
                {isLogin || redirectedAfterRegistration ? 'Vreau un cont nou' : 'Am deja cont'}
              </button>
              <div className={classes.captcha}>
       
       {/* production key  */}
          <ReCAPTCHA
          sitekey="6LeN2cIoAAAAAJC69rlOv93iL-s4MOqZ2Ebc_Yxa"
          onChange={onRecaptchaChange}
          
      />

          {/* test key */}
        {/* <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" 
        onChange={onRecaptchaChange} />   */}

        </div>
            </div>
          </>
        )}
         
      </form>
      {showSuccessModal && (
        <SuccessModal isOpen={showSuccessModal} onClose={closeSuccessModal} customText={successModalText} />
      )}
    </section>
    );
  };

export default AuthForm;
