import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import UserProfile from './components/Profile/UserProfile';
import AuthPage from './pages/AuthPage';
import HomePage from './pages/HomePage';
import AuthContext from './store/auth-context';
import Exchange from './exchange/Exchange';
import ContactPage from './pages/ContactPage';
import Blog from './blog/blogMain';
import Article1 from './pages/Article1';
import Article2 from './pages/Article2';
import Article3 from './pages/Article3';
import Article4 from './pages/Article4';
import Article5 from './pages/Article5';
import Article6 from './pages/Article6';
import Wallet from './components/Wallet/Wallet';
import Footer from './Footer/Footer';
import classes from './App.module.css';
import AboutPage from './pages/About';


function App() {
  const authCtx = useContext(AuthContext);

  return (
    // <div className={classes.background}>
    <div style={{backgroundColor:'#333333'}}>

    <Layout>
  
      <Routes>
      <Route path="/" element={<HomePage />} />
        {!authCtx.isLoggedIn && (
          <Route path="/exchange/auth" element={<AuthPage />} />
        )}
         <Route
            path="/profile"
            element={
              authCtx.isLoggedIn ? (
                <UserProfile />
              ) : (
                <Navigate to="/exchange/auth" />
              )
            }
          />
        <Route path='*' element={<Navigate to='/' />} />
        {/* <Route path='/Bitcoin' element={<BlogBitcoin/>} /> */}
        <Route path="/exchange" element={<Exchange />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/article/1" element={<Article1/>}/>
        <Route path="/blog/article/2" element={<Article2/>}/>
        <Route path="/blog/article/3" element={<Article3/>}/>
        <Route path="/blog/article/4" element={<Article4/>}/>
        <Route path="/blog/article/5" element={<Article5/>}/>
        <Route path="/blog/article/6" element={<Article6/>}/>
        <Route path="/wallet" element={<Wallet/>}/>
        <Route path="/profile" element={<UserProfile/>}/>
        <Route path="/about" element={<AboutPage/>}/>
      </Routes>
    
      <Footer/>
      
    </Layout>
    </div>
  );
}


export default App;