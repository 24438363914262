import AuthForm from '../components/Auth/AuthForm';
import React from 'react';
const AuthPage = () => {
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <AuthForm />;
};

export default AuthPage;