import MainNavigation from '../components/Layout/MainNavigation';
import classes from '../components/StartingPage/StartingPageContent.module.css';
import classesExchange from './ExchangeElements.module.css';
import { useContext } from 'react';
import TrackingBTC from './Tracker';
import { Link } from 'react-router-dom';
import '../components/StartingPage/Custom.css';
import BitcoinCalculatorTest from './btcCalculator';
import React from 'react';
import sageata from '../images/sageata.png';
import AuthContext from '../store/auth-context';
const Exchange = () => {

    const authCtx = useContext(AuthContext);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{marginTop:"5%"}}>


            <section className={classesExchange.container}>
                {/* 
                        <section className={classesExchange.testContainer}>        
                                                            
                    <BitcoinCalculatorTest/>

              </section> */}
                <section className={classesExchange.firstRectangle}>
                    <h1 style={{ fontSize: '36px', color: "#f5f5f5", marginBottom: 40 }}>Vrei ca afacerea ta să accepte Bitcoin?</h1>
                    {/* <img src={sageata} alt="Arrow Pointing Downwards" style={{width: "400px", rotate: '180deg', marginTop:"-5%", marginLeft:"10%"}} /> */}

                    <p style={{ fontSize: '18px', color: "#f5f5f5", marginBottom: 40 }}>Cu noi e zis și făcut!</p>
                    <Link to='/contact'>
                        <button className='button' style={{ fontSize: '20px'}}>Contactează-ne</button>
                    </Link>


                </section>







                <section className={classesExchange.secondRectangle}>


                    <h1 style={{ fontSize: '36px', color: "#f5f5f5", marginBottom: 40 }}>Știai că plățile în Bitcoin sunt avantajoase?</h1>

                    <div>
                        <p style={{ fontSize: '18px', color: "#f5f5f5", marginBottom: 40 }}>Folosind aplicația noastră de 
                        
                        {!authCtx.isLoggedIn ? (<Link to='/wallet' style={{ color: '#FFA500' }}> Lightning Wallet</Link> ) : ( <Link to='/exchange/auth' style={{ color: '#FFA500' }}>Lightning Wallet</Link> ) } aveți reduceri când plătiți
                         <br></br>
                         cu Bitcoin
                            în locațiile <Link to='/' style={{ color: '#FFA500' }}>partenere.</Link></p>


                        <h2 style={{ fontSize: '28px', color: "#f5f5f5" }}>Nu deții bitcoin? Uite care sunt pașii:</h2>


                        <p style={{ fontSize: '18px', color: "#f5f5f5" }}>1. Îți <Link to='/exchange/auth' style={{ color: '#FFA500' }}>creezi un cont</Link> pentru a cumpăra Bitcoin,</p>


                        <p style={{ fontSize: '18px', color: "#f5f5f5" }}>2. Intrii pe <Link to='/wallet' style={{ color: '#FFA500' }}>portofel</Link>,</p>


                        <p style={{ fontSize: '18px', color: "#f5f5f5", marginBottom: 40 }}>3. Îi folosești scanând codul QR aflat în magazinul tău preferat.</p>

                        {!authCtx.isLoggedIn ? (
                            <Link to='/exchange/auth'>
                                <button className='button' style={{ fontSize: '20px', display: "inline-block", marginLeft: '18%' }}>Cumpără Bitcoin</button>
                            </Link>
                        ) : (
                            <Link to='/wallet'>
                                <button className='button' style={{ fontSize: '20px', display: "inline-block", marginLeft: '18%' }}>Cumpără Bitcoin</button>
                            </Link>

                        )}

                    </div>



                </section>



                {/* <TrackingBTC />  */}

            </section>




        </div>);
};

// trebuie pus sa fie pe mijlocul paginii
// <h2 style={{ display: 'flex', justifyContent: 'center' }}>Îți dorești ca afacerea ta să accepte Bitcoin?</h2>
// <p style={{ fontSize: '18px' }}>Două zile, un sticker și s-a rezolvat!</p>
// <Link to='/contact'>
// <button className='button' style={{fontSize:'20px', display: "inline-block"}}>Contactează-ne</button>
//    </Link>
export default Exchange;

