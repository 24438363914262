import classes from '../blog/BlogElements.module.css';
import Back from '../components/Layout/BackButton';
import { Link } from 'react-router-dom';
import NextPreviousButtons from '../blog/NextPreviousButtons';

const Article4 = () => {

 


  return (
    <div className={classes.container}>
      <br></br>
      <header className={classes.header}>
        <div style={{ marginLeft: "-40px" }}> <Back /></div>

        <h1 className={classes.title} style={{ marginTop: "20px" }}>De ce nu încurajăm crypto?</h1>
      </header>

      <main className={classes.main}>
        <article className={classes.article}>
          <section className={classes.section}>
            <h2 className={classes.subtitle}>Introducere</h2>
            <p className={classes.paragraph}>
            Evident, Bitcoin nu este singura monedă digitală care există. Noi, alături de ceea ce domeniul numește 'Bitcoin maximaliști', avem concluzia că Bitcoin este singura monedă de schimb care este incoruptibilă. În acest articol vom explora ce sunt criptomonedele, de ce este important ca o monedă să fie incoruptibilă și de ce noi ne bazăm doar pe Bitcoin.</p>
            <br></br>
<br></br>
<h3 className={classes.subtitle}> Ce este o criptomonedă?</h3>
          
              <p className={classes.paragraph}>Este o monedă digitală care folosește algoritmi de criptare pentru a asigura securitatea sistemului care ține istoricul tranzacțiilor. Moneda digitală care a impus standardul este Bitcoin. Restul monedelor sunt doar o încercare de a face bani și de a îndepărta oamenii de valorile pe care le apără Bitcoin. Deoarece Bitcoin este un sistem descentralizat, nimeni nu poate controla rețeaua. Astfel, lumea a încercat să atragă utilizatorii prin sisteme îmbunătățite, mai bine optimizate, sperând să dețină măcar un procent de control.</p>
              <br></br>
              
              <h3 className={classes.subtitle}>De ce incoruptibilă?</h3>

              <p className={classes.paragraph}>Dintre toate caracteristicile unei monede, incoruptibilitatea este o caracteristică neîntâlnită în istoria umană. Mereu când a existat o cerere mai mare pentru bani, cum erau aurul, argintul, cuprul, omul a găsit mai multă resursă pentru a atinge echilibrul. În cazul bitcoin, o cerere mai mare nu poate rezulta în mai mult de 21 000 000 de monede. Sub nicio formă nu se poate. Aici este frumusețea sistemului. În prezent, guvernele, când au nevoie de bani pentru diverse proiecte, nu se gândesc mereu cum să producă mai multă valoare pentru a putea plăti. Este mult mai ușor să îi tipărească și să ceară taxe. Cei care suferă într-un astfel de sistem sunt cei care nu au voie să tipărească banii. Același lucru se poate întâmpla mai devreme sau mai târziu cu orice criptomonedă, din moment ce există control centralizat.</p>
              <br></br>

              <h3 className={classes.subtitle}>Despre opinia noastră</h3>

              <p className={classes.paragraph}>Nu negăm utilizarea tehnologiei care stă la baza Bitcoin. Spre exemplu, blockchain pentru administrarea depozitelor logistice, semnăturile digitale pentru verificarea autenticității programelor software, emailurilor, etc. Ce facem este să eliminăm conceptul că avem nevoie de alte monede digitale. Una care este adoptată global și este descentralizată este suficientă pentru a prospera ca umanitate. Bitcoin este soluția.</p>
            <br></br>
      
            <br></br>
            <h4 className={classes.subtitle}>Vrei să înveți despre Bitcoin?</h4>
            <p className={classes.paragraph}> Îți recomandăm să încerci <Link to='/blog' style={{ color: '#87CEEB' }}>celelalte articole</Link> sau chiar <a style={{color: '#87CEEB'}} href='https://ro.wikipedia.org/wiki/Bitcoin'>Wikipedia.</a>
            </p>

            <p className={classes.paragraph}>

              <br />
              <br />
              Noi, Bit Bit, oferim <Link to='/exchange/auth' style={{ color: '#FFA500' }}>structura necesară </Link>
 comercianților pentru a accepta astfel de plăți și ție <Link to='/exchange/auth' style={{ color: '#FFA500' }}>o metodă de a plăti </Link>folosind Bitcoin.
            </p>
          </section>

          <NextPreviousButtons/>

        </article>
       
      </main>
    </div>
  );
}

export default Article4;
