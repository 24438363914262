// AboutPage.js
import React from 'react';
import classes from '../components/About.module.css';
import logo from '../images/logo bitbit alb.png'
const AboutPage = () => {
  return (
    <div className={classes.container}>
      <div className={classes.centeredContent}>
        <img src={logo} alt="Company Logo" className={classes.logo} />
        <h1 style={{color: "#F5F5F5",fontSize:'32px', marginTop:'10vh', display:'flex', alignContent:'flex-start'}}>Ce este BitBit?</h1>
        <p className={classes.leftParagraph} style={{fontSize:'20px', color: "#F5F5F5"}}>
        BitBit este o platformă care permite întreprinderilor să accepte plăți în Bitcoin indiferent de domeniul de activitate.
        
        Totul a început în momentul în care am descoperit că Bitcoin este cea mai puternică metodă de plată. <br></br> Prin aducerea acestor plăți știu că voi influența benefic societatea. Folosind o monedă de schimb a cărei politică monetară nu poate fi schimbată, toată lumea are de câștigat.

        </p>
        <div className={classes.photoSection}>
          <div className={classes.roundPhoto}>
            <img src="photo1.jpg" alt="Photo 1" />
          </div>
          <div className={classes.roundPhoto}>
            <img src="photo2.jpg" alt="Photo 2" />
          </div>
          <div className={classes.roundPhoto}>
            <img src="photo3.jpg" alt="Photo 3" />
          </div>
          <div className={classes.roundPhoto}>
            <img src="photo4.jpg" alt="Photo 4" />
          </div>
        </div>
        <p className={classes.centeredParagraph} style={{fontSize:'20px', color: "#F5F5F5"}}>
        Eu sunt Eric-Gabriel Pican. Am început singur acest vis, dar vreau să îl continui cu tine. Dacă vrei să mi te alături, fie ca și client, fie să începi o carieră, scrie-mi un mail pe ericpican@bitbit.ro .
        </p>
      </div>
    </div>
  );
}

export default AboutPage;
