import classes from "./Footer.module.css"

const Footer = () => {
    return (
      <footer className={classes.styles}>
        <p style={{color:"#F2F2F2"}}>&copy; 2023 Bit Software Development. All rights reserved.</p>
        
        <p> <a href="/" style={{color:"#FFA500"}}>Acasă</a> | <a href="/about" style={{color:"#FFA500"}}>Despre noi</a> | <a href="/contact" style={{color:"#FFA500"}}>Contact</a></p>
      </footer>
    );
  }

  export default Footer;