import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firebaseConfig from '../Auth/FirebaseConfig';
import classes from './Wallet.module.css';

const ProgressBar = () => {
  const db = firebase.firestore();

  const [missionsData, setMissionsData] = useState([]);
  const [uid, setUid] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [referralCount, setReferralCount] = useState(null);
  const [progressButtonStatusFromMission, setProgressButtonStatusFromMission] = useState(null);

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    // Add a listener to Firebase Authentication to get the user ID
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is logged in, set the user ID to the state
        setUid(user.uid);
      } else {
        // User is not logged in, set the user ID to null
        setUid(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);


  const handleButtonClick = () => {
    setProgressButtonStatusFromMission(1);
  };


  useEffect(() => {
    if (!uid) {
      return;
    }

    const missionsRef = db.collection('users').doc(uid).collection('missions');

    // Fetch the referralCount directly from the user's document
    db.collection('users')
      .doc(uid)
      .get()
      .then((userDoc) => {
        if (userDoc.exists) {
          const fetchedReferralCount = userDoc.data().referralCount;
          setReferralCount(fetchedReferralCount);
          const fetchedProgressButtonStatusFromMission = userDoc.data().progressButtonStatusFromMission;
          setProgressButtonStatusFromMission(fetchedProgressButtonStatusFromMission);
          handleIncrementProgress();
        } else {
          console.log('User document does not exist.');
        }
      })
      .catch((error) => {
        console.error('Error fetching user document:', error);
      });


    // Listen for changes to the 'missions' collection and update the missionsData state accordingly
    const unsubscribe = missionsRef.onSnapshot((snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setMissionsData(data);
    });

    // Cleanup function to remove the listener when the component unmounts
    return () => unsubscribe();
  }, [uid]);

  const toggleModal = (mission) => {
    if (modalVisible && modalVisible.id === mission.id) {
      setModalVisible(null); // Close the modal if it's already open
    } else {
      setModalVisible(mission); // Show the modal with the selected mission data
    }
  };

  const renderModalContent = (mission) => {
    return (
      <div className={classes.modalContent}>
        <button className={classes.closeButton} onClick={() => toggleModal(mission)}>x</button>
        <h3>{mission.name}</h3>
        <p>{mission.text}</p>
      </div>
    );
  };

  // Function to increment progress of a mission by a given amount
  const incrementMissionProgress = (missionId, amount) => {
    
    const missionsRef = db.collection('users').doc(uid).collection('missions');
    missionsRef
      .doc(missionId)
      .update({
        progress: firebase.firestore.FieldValue.increment(amount),
      })
      // .then(() => {
      //   console.log("Progress updated successfully");
      // })
      .catch((error) => {
        console.error("Error updating progress:", error);
      });
  };


  useEffect(() => {
    // Ensure both uid and referralCount are available
    if (uid !== null && referralCount !== null) {
      // Logic for incrementing progress
      if (referralCount * 10 <= 100) {
        const newReferralCount = referralCount * 10;
        incrementMissionProgress('mission1', newReferralCount);
      } else if (referralCount * 10 >= 100) {
        incrementMissionProgress('mission1', 100);
      }
     
    } 
    if( uid !== null && progressButtonStatusFromMission !== null)
    {incrementMissionProgress('mission3', progressButtonStatusFromMission *100);
    db.collection('users').doc(uid).update({
      progressButtonStatusFromMission: progressButtonStatusFromMission,
    })
  }
  }, [uid, referralCount, progressButtonStatusFromMission]);

  // Logic for incrementing progress
  const handleIncrementProgress = () => {
    if (referralCount !== null && referralCount * 10 <= 100) {
      const newReferralCount = referralCount * 10;
      incrementMissionProgress('mission1', newReferralCount);
    } else if (referralCount * 10 >= 100) {
      incrementMissionProgress('mission1', 100);
    }
    {incrementMissionProgress('mission3', progressButtonStatusFromMission *100);
    db.collection('users').doc(uid).update({
      progressButtonStatusFromMission: progressButtonStatusFromMission,
    })
  }
  };

  return (
    <div>
      <div className={classes.missionsContainer} style={{ zIndex: "999" }}>
        {missionsData.map((mission) => (
          <div key={mission.id} className={classes.mission}>
            <div className={classes.missionName}>{mission.name}</div>
            <div className={classes.progressBar}>
              <div className={classes.progress} style={{ width: `${mission.progress}%` }}>
                {mission.progress}%
              </div>
            </div>
            <div className={classes.buttonDetailsContainer}>
              <button className={classes.buttonDetails} onClick={() => toggleModal(mission)}>
                i
              </button>
            </div>
            {modalVisible && modalVisible.id === mission.id && (
              <div className={classes.modal}>{renderModalContent(mission)}</div>
            )}
          </div>
        ))}
                    <button className={classes.buttonDetails} style={{width:'150px'}} onClick={(handleButtonClick)}>Buttonul</button>

      </div>
    </div>
  );
};

export default ProgressBar;
