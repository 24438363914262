import classes from "./Contact.module.css";
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import emailjsConfig from './EmailJS';
import ReCAPTCHA from "react-google-recaptcha";
import SuccessModal from '../Layout/PopUpBox';

function ContactForm() {

  
  const initialFormData = {
    name: '',
    email: '',
    message: '',
  };

const [formData, setFormData] = useState(initialFormData);
const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
const [isModalOpen, setModalOpen] = useState(false);
const buttonRef = useRef ();
const [customText, setCustomText] = useState('');

  const { serviceID, templateID, pubKey } = emailjsConfig;

  const handleChange = (event) => {
    const inputName = event.target.name; // Get the name attribute of the input
    const inputValue = event.target.value;
  
    const nameToStateKeyMap = {
      user_name: "name",
      user_email: "email",
      user_message:"message"
    };
  

    setFormData({
      ...formData,
      [nameToStateKeyMap[inputName]]: inputValue,
    });
  };

  
  const form = useRef();


  const onRecaptchaChange = (value) => {
    // This function is called when reCAPTCHA is solved
    setIsRecaptchaVerified(true);
  };


  const sendEmail = (e) => {
    e.preventDefault();
  
   
    if (!isRecaptchaVerified) {
       setCustomText('Please complete the reCAPTCHA challenge.');
      return;
    }
    if (!formData.name || !formData.email || !formData.message) {
      setModalOpen(true);
      setCustomText( "Verificați dacă ați completat în întregime formularul.") ;
      setTimeout(() => {
        setModalOpen(false);
        setCustomText("");
      }, 5000); 
      return;
    }
    
    emailjs.sendForm(serviceID, templateID, form.current, pubKey)
      .then((result) => {
        console.log(result.text);
        setModalOpen(true);
        setCustomText ("Success! Mesajul tău a fost transmis și vei fi contactat.") ;
        // Reset the formData
        setTimeout(() => {
          setFormData(initialFormData);
        }, 2000);

        setTimeout(() => {
          setModalOpen(false);
          setCustomText('');
        }, 5000); 
      
      })
      .catch((error) => {
        console.log(error.text);
      });
  };



  return (
    <div className={classes.container}> 
    <form ref={form} onSubmit={sendEmail}>
   
      <label >
        
        <input className={classes.inputBoxes}  
         placeholder="Numele dvs. este"
          type="text"
          name="user_name"
          value={formData.name}
          onChange={handleChange}
        />
      </label>
    
      <br />

      <label>
       
        <input className={classes.inputBoxes}
         placeholder="iar emailul dvs. este"
          type="email"
          name="user_email"
          value={formData.email}
          onChange={handleChange}
        />
      </label>
   
      <br />
 
      <label className={classes.font}>
        Doriți să optați și pentru alte servicii <br></br>(Promovarea afacerii, promoții, training suplimentar, etc.)?
        <br></br>
        <select style={{ width: "50%" }} name="raspuns">
            
          <option value="yes">Da</option>
          <option value="no">Nu</option>
        </select>
<br></br>

        <br></br>Descrieți pe scurt cum doriți să personalizăm <br></br>serviciile alese (vom detalia într-o întâlnire).
    
        <textarea className={classes.inputBoxes}
        
          name="user_message"
          value={formData.message}
          onChange={handleChange}
          style={{marginTop:'10px'}}
        />
      </label>
  
      <br />
      {/* production key  */}
      <ReCAPTCHA
          sitekey="6LeN2cIoAAAAAJC69rlOv93iL-s4MOqZ2Ebc_Yxa"
          onChange={onRecaptchaChange}
        />

        {/* test key */}
        {/* <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" 
        onChange={onRecaptchaChange} />   */}

      <button 
      className={classes.button}
       type="submit" 
       disabled={!isRecaptchaVerified}
        ref={buttonRef}
       >Trimite</button>
      
    </form>
  {isModalOpen && <SuccessModal isOpen={isModalOpen} customText={customText} onClose={() => setModalOpen(false)} buttonRef={buttonRef} />}    </div>
    
  );
}


export default ContactForm;