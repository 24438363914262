import React from 'react';
import classes from './BlogElements.module.css';
const ArticleBox = (props) => {
  return (

    <div className={classes.articlebox} onClick={() => window.location.href = props.url}>
        <img src={props.imgUrl} className={classes.articleboxImg} alt={props.title} />
      <h3 className={classes.articleboxH3}>{props.title}</h3>
    </div>
    
  );
}

export default ArticleBox;