import classes from '../blog/BlogElements.module.css';
import Back from '../components/Layout/BackButton';
import { Link } from 'react-router-dom';
import NextPreviousButtons from '../blog/NextPreviousButtons';

const Article1 = () => {

 


  return (
    <div className={classes.container}>
      <br></br>
      <header className={classes.header}>
        <div style={{ marginLeft: "-40px" }}> <Back /></div>

        <h1 className={classes.title} style={{ marginTop: "20px" }}>Bitcoin și Democrația</h1>
      </header>

      <main className={classes.main}>
        <article className={classes.article}>
          <section className={classes.section}>
            <h2 className={classes.subtitle}>Introducere</h2>
            <p className={classes.paragraph}>
            În era digitală actuală, Bitcoin a devenit o forță semnificativă în lumea financiară și economică. Pe lângă beneficiile sale ca activ financiar și tehnologie revoluționară, Bitcoin oferă o oportunitate unică de a promova principiile de bază ale democrației în societatea noastră. În acest articol, vom explora modul în care Bitcoin poate susține valorile democratice și cum politica sa monetară robustă poate aduce beneficii societății noastre.
            </p>
            <br></br>
            <strong className={classes.paragraph}> Democrația se bazează pe principii cheie observate în funcționarea rețelei Bitcoin.</strong>
           <br></br> <br></br>
            <ol className={classes.list}>
              <li>Transparența: Tehnologia blockchain, pe care se bazează Bitcoin, asigură un nivel ridicat de transparență. Toate tranzacțiile Bitcoin sunt înregistrate în mod public și verificabile în mod deschis de oricine. Această transparență crește încrederea în sistem și contribuie la eliminarea corupției și fraudei.</li>
             <br></br>
              <li> Descentralizarea: În contrast cu sistemele tradiționale bancare și financiare rețeaua Bitcoin nu are un organism central care să controleze sau să reglementeze tranzacțiile. Acest lucru oferă puterea înapoi în mâinile utilizatorilor individuali, promovând astfel principiul separării puterilor în stat.</li>
              <br></br>
              <li> Utilizatorii dețin controlul: Bitcoin oferă posibilitatea fiecărui utilizator de a deține și de a controla propriile fonduri fără intermediari.</li>
            </ol>
            <br></br>
              <strong className={classes.paragraph}>Bitcoin face separarea banilor de stat.</strong>
              <br></br>            <br></br>
            <h3 className={classes.subtitle}> Unde pot citi mai multe despre Bitcoin?</h3>
            <p className={classes.paragraph}> Îți recomandăm să încerci <Link to='/blog' style={{ color: '#87CEEB' }}>celelalte articole</Link> sau chiar <a style={{color: '#87CEEB'}} href='https://ro.wikipedia.org/wiki/Bitcoin'>Wikipedia.</a>
            </p>

            <p className={classes.paragraph}>

              <br />
              <br />
              Noi, Bit Bit, oferim <Link to='/exchange/auth' style={{ color: '#FFA500' }}>structura necesară </Link>
 comercianților pentru a accepta astfel de plăți și ție <Link to='/exchange/auth' style={{ color: '#FFA500' }}>o metodă de a plăti </Link>folosind Bitcoin.
            </p>
          </section>

          <NextPreviousButtons/>

        </article>
       
      </main>
    </div>
  );
}

export default Article1;
