import ContactForm from '../components/Contact/ContactForm.js';
import classes from '../exchange/ExchangeElements.module.css';
import React from 'react';
const ContactPage = () => {
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
     
    return (
        <div >
            <section className={classes.container} style={{marginLeft:"5%"}}> 
    <ContactForm />
   <div className={classes.firstRectangle} style={{marginLeft:"0"}}>
        <h2 style={{fontSize:"36px", color:"#F2F2F2", marginTop:"5%"}}>Despre procesul de implementare</h2>
        <p style={{fontSize:"18px", color:"#F2F2F2", marginTop:"3%"}}>După ce aderați și completăm contractul de tranzacționare cu Bitcoin, 
        <br></br>o să aveți acces în aplicație la rețeaua de tranzacționare. 
        <br></br>În funcție de opțiunile alese, veți primi materiale 
        <br></br>promoționale și abțibilduri. Codul QR poate fi generat și din aplicație.
        <br></br>
        <br></br>
     </p>

   <p style={{fontSize:"18px", color:"#F2F2F2"}}>Puteți oricând opta pentru opțiunile de promovare, training suplimentar, <br></br> evenimente alături de alte locații partenere.</p>
   <br></br>
   <p style={{fontSize:"18px", color:"#F2F2F2", justifyContent:"center"}}>Date de Contact</p>
   <br></br>
    <p style={{fontSize:"18px", color:"#F2F2F2"}}>Eric Pican</p>
    <p style={{fontSize:"18px", color:"#F2F2F2"}}>Nr. telefon : +40725179323</p>
    </div>

    </section>
    </div>
    )
  };


export default ContactPage;