import classes from './StartingPageContent.module.css';
import './Custom.css';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';



const StartingPageContent = () => {

  const authCtx = useContext(AuthContext);

  return (
    <section className={classes.wholePage}>
      <section className={classes.startingToolbar}>


      </section>
      <section className={classes.startingButtons}>

      {!authCtx.isLoggedIn ? (
        <Link to='/exchange/auth'>
          <button className="button" style={{height: "100px"}}>Vreau să plătesc <br></br>cu Bitcoin</button>
        </Link> ) :
        (
          <Link to='/wallet'>
          <button className="button" style={{height: "100px"}}>Vreau să plătesc <br></br>cu Bitcoin</button>
        </Link>
        )}

        <Link to='/blog'>
          <button className="button" style={{height: "100px"}}>Citește  despre <br></br>Bitcoin</button>
        </Link>
        <Link to='/exchange'>
          <button className="button" style={{height: "100px"}}>Vreau să primesc  <br></br>plăți în Bitcoin</button>
        </Link>

      </section>
    </section>
  );
};

export default StartingPageContent;