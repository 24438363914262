import React, { useState } from 'react';
import classes from "./Wallet.module.css";
function Popup() {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");

  const handleSubmit = () => {
    // Handle submission of form data here
    console.log(input1, input2);
    // setShowPopup(false);
  };

  return (
    <div>
        <div className="popup">
          <div className="popup-inner">
           
            <form className={classes.button} onSubmit={handleSubmit}>
              <label>
                Adresa de lightning:
                <input className={classes.submitStyle}type="text" value={input1} onChange={(e) => setInput1(e.target.value)}/>
              </label>
              <br />
              <label>
                Suma:
                <input className={classes.submitStyle}  style={{marginLeft:"40.6%", marginTop:"3%"}} type="text" value={input2} onChange={(e) => setInput2(e.target.value)} />
              </label>
              <br />
              <button className={classes.button2} style={{fontSize:"14px", marginRight:"33%"}} type="submit">Trimite</button>
            
            </form>
          </div>
        </div>
    
    </div>
  );
}


export default Popup;
