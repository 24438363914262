import React, { useState, useEffect, useRef } from 'react';
import StartingPageContent from '../components/StartingPage/StartingPageContent';
import Map from '../components/Map/map.js';
import classes from '../components/StartingPage/StartingPageContent.module.css';
import classesBlob from '../components/StartingPage/BlobComponent.module.css';
import BlobComponent from '../components/StartingPage/Blobs';
import { Link } from 'react-router-dom';
import PhotoLeft from '../images/test.jpeg';
import Appstore from '../images/appstore.png';
import Googlestore from '../images/googleplay.png';


const HomePage = () => {
  const [animateA, setAnimateA] = useState(false);
  const [animateB, setAnimateB] = useState(false);
  const [animateC, setAnimateC] = useState(false);

  const animatedElementRefA = useRef(null);
  const animatedElementRefB = useRef(null);
  const animatedElementRefC = useRef(null);

  useEffect(() => {
    const observerA = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          // Element A is in the viewport, trigger the animation
          setAnimateA(true);
          observerA.unobserve(animatedElementRefA.current);
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const observerB = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          // Element B is in the viewport, trigger the animation
          setAnimateB(true);
          observerB.unobserve(animatedElementRefB.current);
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const observerC = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          // Element C is in the viewport, trigger the animation
          setAnimateC(true);
          observerC.unobserve(animatedElementRefC.current);
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    // Observe the animated elements
    if (animatedElementRefA.current) {
      observerA.observe(animatedElementRefA.current);
    }

    if (animatedElementRefB.current) {
      observerB.observe(animatedElementRefB.current);
    }

    if (animatedElementRefC.current) {
      observerC.observe(animatedElementRefC.current);
    }

    // Cleanup observers
    return () => {
      if (animatedElementRefA.current) {
        observerA.unobserve(animatedElementRefA.current);
      }
      if (animatedElementRefB.current) {
        observerB.unobserve(animatedElementRefB.current);
      }
      if (animatedElementRefC.current) {
        observerC.unobserve(animatedElementRefC.current);
      }
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        ref={animatedElementRefA}
        className={`${classes.animatedElementA} ${animateA ? classes.animatedSlideFade : ''}`}
      >
        <h1 className={classes.textMiddle} style={{ marginTop: "40px", marginBottom: "-20px", color: "#F5F5F5" }}>Plătește cu Bitcoin Bit Bit de repede</h1>
      </div>
      <div
        ref={animatedElementRefB}
        className={`${classes.animatedElementB} ${animateB ? classes.animatedAppearance : ''}`}
      >
        <StartingPageContent />
       
        <div
          ref={animatedElementRefA}
          className={`${classes.animatedElementA} ${animateA ? classes.animatedSlideFade : ''}`}
        >
          <h2 className={classes.textMiddle} style={{ marginBottom: "40px", color: "#F5F5F5" }}>Caută locațiile unde poți plăti cu Bitcoin</h2>
        </div>
        <section className={classes.mapFlex}>
          <Map width="70%" height="60vh" />
        </section>
      </div>
      <h3 className={classes.textMiddleB} style={{ marginTop: "60px", color: "#F5F5F5", marginBottom: "50px" }}> Facilităm acceptarea plăților în Bitcoin pentru firme</h3>
      <div
        ref={animatedElementRefC}
        className={`${classes.animatedElementB} ${animateC ? classes.animatedAppearance : ''}`}
      >
        <div className={classesBlob.blobContainer}>
          <div className={classesBlob.blobWrapper}>
            <BlobComponent />
          </div>
          <div className={classesBlob.blobWrapper}>
            <BlobComponent />
          </div>
          <div className={classesBlob.blobWrapper}>
            <BlobComponent />
          </div>
          <div className={classesBlob.blobWrapper}>
            <BlobComponent />
          </div>
        </div>

        {/* trebuie in BlobComponent logoSrc={logo1} */}
      </div>

      <h4 className={classes.textMiddleB} style={{ marginTop: "60px", color: "#F5F5F5", marginBottom: "30 px" }}>Plățile în Bitcoin sunt avantajoase în termen lung.</h4>
    
      <div className={classes.containerPhotoFirst}>
      <img src={PhotoLeft} alt="A woman purchasing coffee with Bitcoin"
          className={classes.photo} style={{zIndex:"2"}}>
            
        </img>
</div>

<div className={classes.containerPhotoSecond}>

<img src={PhotoLeft} alt="A woman purchasing coffee with Bitcoin"
          className={classes.photo} style={{zIndex:"2"}}>
            
        </img>

</div>

          <div className={classes.lastPart}>
        <h5 className={classes.textMiddleB} style={{color: "#F5F5F5"}}>Vrei să plătești sau să accepți plăți în Bitcoin?</h5>
          
       <div className={classes.downloadPictures}>
        <img src={Appstore} className={classes.individualDownloadPictures}></img>
        <img src={Googlestore} className={classes.individualDownloadPictures}></img>
       </div>

          <a className={classes.textMiddleB} style={{marginTop:"30px",marginBottom:"30px", color: "#F5F5F5"}}> <Link to='/contact' className={classes.textMiddleB} style={{ color: '#FFA500' }}>Contactează-ne </Link>&nbsp;sau intră direct în aplicație.</a>
          <a className={classes.textMiddleB} style={{color: "#F5F5F5"}}>Te invităm să citești&nbsp;<Link to='/blog' className={classes.textMiddleB} style={{ color: '#FFA500' }}>despre Bitcoin.</Link></a>
    </div>
    
    </div>);
};

export default HomePage;