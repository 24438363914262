import React, { useEffect, useState } from "react";
import classes from './Wallet.module.css';
import InfoIcon from "./WalletInfograph";
import { Link } from 'react-router-dom';
import Popup from './Popup';
import Scan from "./ScanQR";
import Map from "../Map/map";
import profileImage from '../../images/user profile.svg';
import IncrementButtonPage from "./TransactionCounter";
import Friends from "./Friends";
import ProgressBar from './ProgressBar';
import Balance from './Balance';
import LightningNetwork from "./LightningNetwork";
import UserInfo from "./LNBits config/UserInfo";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebaseConfig from '../Auth/FirebaseConfig';

const Wallet = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const scrollThreshold = 220;
  const [isScanVisible, setIsScanVisible] = useState(false);
  const [showTransactionPage, setShowTransactionPage] = useState(false);

  // Initialize Firebase only once
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  useEffect(() => {
    // Set Firebase authentication persistence to 'LOCAL'
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }, []);

  const handleSendTransactionClick = () => {
    setShowTransactionPage(true);
  };

  const handleScroll = () => {
    if (window.scrollY > scrollThreshold) {
      setIsAnimated(true);
    } else {
      setIsAnimated(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggleScan = () => {
    setIsScanVisible(!isScanVisible);
  };

  useEffect(() => {
    if (isScanVisible) {
      // Start the ScanQR component automatically when isScanVisible is true
    }
  }, [isScanVisible]);

  const scrollToSection = (targetElementId) => {
    const element = document.getElementById(targetElementId);
    if (element) {
      const startY = window.scrollY;
      const endY = element.getBoundingClientRect().top + window.pageYOffset;
      const duration = 1000;
      const distance = endY - startY;
      const stepSize = distance / duration * 7.5;

      const scroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const scrollY = startY + easeInOutQuad(Math.min(elapsedTime / duration, 1)) * distance;

        window.scrollTo(0, scrollY);

        if (elapsedTime < duration) {
          requestAnimationFrame(scroll);
        }
      };

      const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const startTime = performance.now();
      requestAnimationFrame(scroll);
    }
  };

  return (
    <div className={classes.wholePage}>
      <div className={classes.containerContent}>
        <div className={classes.containerTop}>
          <div className={classes.balanceThreeButtons}>
            <Balance />
            {isScanVisible && <Scan />}
          </div>
          <div className={classes.buttonContainer}>
            <button id="scan" className={classes.buttonScurt} style={{ zIndex: "999" }} onClick={handleToggleScan}>scan</button>
            <button id="topup" className={classes.buttonScurt} style={{ zIndex: "999" }} >topup</button>
          </div>
        </div>
        <h1 style={{ color: "#F2F2F2", marginLeft: "-70%", fontSize: "32px", marginTop: "20px" }}>Misiuni</h1>
        <ProgressBar />
        <h2 id="friends" style={{ color: "#F2F2F2", marginLeft: "-65%", fontSize: "32px" }}>Prieteni</h2>
        <Friends></Friends>
        {/* <LightningNetwork></LightningNetwork> */}
        {/* <UserInfo></UserInfo> */}
        <div style={{ marginTop: "1000px" }}></div>
        {/* <div>
          {showTransactionPage ? (  
            <Popup></Popup>
          ) : (
            <button className={classes.button} onClick={handleSendTransactionClick} />
          )}
        </div> */}
        {/* <button className={classes.button}>Primește</button> */}
      </div>
      <div className={classes.floatingButtonsContainer}>
        <div className={isAnimated ? `${classes.floatingButtons} ${classes.animated}` : classes.floatingButtons}>
          <div className={classes.main}>
            <div className={classes.container}>
              <Link to="/profile"><button className={classes.floatingButton}>Profil</button></Link>
            </div>
          </div>
          <div className={classes.main}>
            <div className={classes.container}>
              <button className={classes.floatingButton} onClick={() => scrollToSection("friends")}>Prieteni</button>
            </div>
          </div>
          <div className={classes.main}>
            <div className={classes.container}>
              <button className={classes.floatingButton} onClick={() => scrollToSection("wallet")}>Portofel</button>
            </div>
          </div>
          <div className={classes.main}>
            <div className={classes.container}>
              <Link to="/profile"><button className={classes.floatingButton}>Setări</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
