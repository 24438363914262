import classes from '../blog/BlogElements.module.css';
import Back from '../components/Layout/BackButton';
import { Link } from 'react-router-dom';
import NextPreviousButtons from '../blog/NextPreviousButtons';

const Article2 = () => {

 


  return (
    <div className={classes.container}>
      <br></br>
      <header className={classes.header}>
        <div style={{ marginLeft: "-40px" }}> <Back /></div>

        <h1 className={classes.title} style={{ marginTop: "20px" }}>Sistemul Bitcoin</h1>
      </header>

      <main className={classes.main}>
        <article className={classes.article}>
          <section className={classes.section}>
            <h2 className={classes.subtitle}>Introducere</h2>
            <p className={classes.paragraph}>
            Bitcoin este o formă de bani digitali care funcționează într-un mod diferit de monedele tradiționale pe care le folosim, precum bancnotele sau monedele. Bitcoin este stocat și transferat într-un mediu digital și se bazează pe o tehnologie numită blockchain. Punctul 3 reprezintă motivul principal pentru care Bitcoin nu poate fi corupt.</p>
            <br></br>
<br></br>
            <ol className={classes.list}>
              <li>Blockchain: Imaginează-ți un caiet în care se notează toate tranzacțiile cu Bitcoin. Acest caiet nu este păstrat de o singură persoană sau organizație, ci este distribuit pe internet, astfel încât multe persoane îl au și îl actualizează constant. De fiecare dată când se trece la următoarea pagină, se notează detalii ale tranzacțiilor de pe pagina anterioară. Acest lucru face imposibilă modificarea tranzacțiilor trecute, deoarece ar modifica tranzacțiile de la acel moment până în prezent și ar fi respinse. </li>
              <br></br>
              <li>Consens: Minerii lucrează pentru a găsi soluția unei ecuații. Soluția, care este un număr, se face mai greu de obținut direct proportional cu puterea de rezolvare a minerilor. Atunci când un miner găsește o soluție validă el poate scrie o pagină de tranzacții. Structura ecuației are proprietatea că <strong>soluția este greu de obținut </strong>, dar <strong>foarte ușor de verificat </strong>dacă este corectă.</li>
            <br></br>
              <li> În rețeaua Bitcoin, pentru a schimba regulile existente, sau chiar tranzacții, trebuie să existe un consens în rândul majorității minerilor  <strong>și utilizatorilor </strong>(50%+1). Și dacă s-ar ajunge la un consens, rețeaua Bitcoin, care ar fi urmat să fie modificată, nu dispare. Cine va dori să o folosească în continuare, va putea. Din acest motiv există atâtea criptomonede cu diferite regulamente.</li>
            </ol>
            <br></br>
            <h3 className={classes.subtitle}> Unde pot citi mai multe despre Bitcoin?</h3>
            <p className={classes.paragraph}> Îți recomandăm să încerci <Link to='/blog' style={{ color: '#87CEEB' }}>celelalte articole</Link> sau chiar <a style={{color: '#87CEEB'}} href='https://ro.wikipedia.org/wiki/Bitcoin'>Wikipedia.</a>
            </p>

            <p className={classes.paragraph}>

              <br />
              <br />
              Noi, Bit Bit, oferim <Link to='/exchange/auth' style={{ color: '#FFA500' }}>structura necesară </Link>
 comercianților pentru a accepta astfel de plăți și ție <Link to='/exchange/auth' style={{ color: '#FFA500' }}>o metodă de a plăti </Link>folosind Bitcoin.
            </p>
          </section>

          <NextPreviousButtons/>

        </article>
       
      </main>
    </div>
  );
}

export default Article2;
