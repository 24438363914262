import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseConfig from '../Auth/FirebaseConfig';
import classes from './Wallet.module.css';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();



const Friends = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const requestsCollection = db.collection("requests");
      const friendRequestsRef = requestsCollection.doc(currentUser.uid).collection("friendRequests");

      const unsubscribeFriendRequests = friendRequestsRef
        .where("status", "==", "requested")
        .onSnapshot((snapshot) => {
          const requestsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setFriendRequests(requestsData);
        });

      return () => {
        unsubscribeFriendRequests();
      };
    }
    
  }, [currentUser]);

  const addFriend = async (e) => {
    e.preventDefault();
    if (currentUser) {
      const requestsCollection = db.collection("requests");

      const normalizedUsername = email.replace(/\s/g, "").toLowerCase();

      const existingFriend = friends.find(
        (friend) => friend.normalizedUsername === normalizedUsername
      );

      if (existingFriend) {
        alert("Friend already exists");
      } else {
        const recipientQuery = await db
          .collection("users")
          .where("usernameCustom", "==", normalizedUsername)
          .limit(1)
          .get();

          const currentUserQuery = await db
          .collection("users")
          .where("uid", "==", currentUser.uid)
          .limit(1)
          .get();

      //     if (currentUserQuery.size > 0) {

      //     const usernameCustom = currentUserQuery.docs[0].data().usernameCustom;
      // }    ASTA TB PUS IN ALT FEL PT CA NU MERGE
        if (recipientQuery.size === 0) {
          alert("Recipient not found");
        } else {

          const recipientUid = recipientQuery.docs[0].id;

      const ownRequestsRef = requestsCollection.doc(recipientUid).collection("ownRequests");

          // Check if a friend request already exists
          const existingRequest = await ownRequestsRef.doc(recipientUid).get();

          if (existingRequest.exists) {
            alert("Friend request already sent");
          } else {
            // Add friend request to recipient's ownRequests
            await requestsCollection
              .doc(currentUser.uid)
              .collection("friendRequests")
              .doc(recipientUid)
              .set({
                displayName: recipientQuery.docs[0].data().usernameCustom,
                status: "requested",
              });

            // Add friend request to sender friendRequests
            await ownRequestsRef.doc(currentUser.uid).set({
              // displayName: ,   AICI NU MERGE CHESTIA DE MAI SUS CU CURRENT USER
              status: "requested",
            });

            setName("");
            setEmail("");
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 3000);
          }
        }
      }
    }
  };

  const acceptFriendRequest = async (request) => {
    try {
      const senderUid = request.id; // Assuming 'id' is the senderUid in your request object
      const senderDisplayName = request.displayName;
  

  
      // if (currentUser.uid !== senderUid) {
      //   console.log("You can only accept friend requests sent to you.");
      //   return;
      // }
  
      // Update friend request status in sender's ownRequests
      await db.collection("requests")
        .doc(currentUser.uid)
        .collection("ownRequests")
        .doc(currentUser.uid) // Use currentUser.uid as the document ID
        .update({
          status: "accepted",
        });
  
      // Update friend request status in recipient's friendRequests
      await db.collection("requests")
        .doc(currentUser.uid)
        .collection("friendRequests")
        .doc(senderUid) // Use senderUid as the document ID
        .update({
          status: "accepted",
        });
  
      // Move friends to the allFriends section
      await db.collection("requests")
        .doc(currentUser.uid)
        .collection("allFriends")
        .doc(senderUid)
        .set({
          displayName: senderDisplayName,
        });
  
      await db.collection("requests")
        .doc(senderUid)
        .collection("allFriends")
        .doc(currentUser.uid)
        .set({
          displayName: currentUser.displayName,
        });
  
      // Remove friend request documents from ownRequests and friendRequests
      await db.collection("requests")
        .doc(currentUser.uid)
        .collection("friendRequests")
        .doc(senderUid)
        .delete();
  
      await db.collection("requests")
        .doc(senderUid)
        .collection("ownRequests")
        .doc(currentUser.uid)
        .delete();
  
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    } catch (error) {
      console.error("Firestore error:", error);
    }
  };
  
  

  return (
    <div className={classes.friendsContainer}>
      {friends.length === 0 ? (
        <div>No friends yet.</div>
      ) : (
        <div>
          <h2>Friends</h2>
          <ul className={classes.friendsList}>
          {/* aici vine map de friends  */}
    </ul>
        </div>
      )}
      {friendRequests.length === 0 ? (
        <div>No friend requests.</div>
      ) : (
        <div>
          <h2>Friend Requests</h2>
          <ul className={classes.requestsList}>
            {friendRequests.map((request) => (
              <li key={request.id}>
                <div>
                  {request.displayName} has sent you a friend request.
                </div>
                <button className={classes.button} onClick={() => acceptFriendRequest(request)}>Accept</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <form onSubmit={addFriend} style={{ zIndex: "999" }} className={classes.addFriendForm}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Alege o poreclă"
        />
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Numele utilizatorului"
        />
        <button className={classes.button} type="submit">Add Friend</button>
      </form>
      {showSuccessMessage && <div style={{ marginLeft: '10px' }}>Friend request sent successfully!</div>}
    </div>
  );
};

export default Friends;