import { useNavigate } from 'react-router-dom';
import classes from './MainNavigation.module.css';
function BackButton() {
  let navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  return (
    <button onClick={handleClick} className={classes.backButton}>
      
    </button>
  );
}

export default BackButton;