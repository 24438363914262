import classes from '../blog/BlogElements.module.css';
import Back from '../components/Layout/BackButton';
import { Link } from 'react-router-dom';
import NextPreviousButtons from '../blog/NextPreviousButtons';

const Article3 = () => {

 


  return (
    <div className={classes.container}>
      <br></br>
      <header className={classes.header}>
        <div style={{ marginLeft: "-40px" }}> <Back /></div>

        <h1 className={classes.title} style={{ marginTop: "20px" }}>Bitcoin și sistemul bancar</h1>
      </header>

      <main className={classes.main}>
        <article className={classes.article}>
          <section className={classes.section}>
            <h2 className={classes.subtitle}>Introducere</h2>
            <p className={classes.paragraph}>
            Bitcoin a prins amploare în lumea financiară nu doar ca investiție, dar ca monedă de schimb, digitală, alternativă sistemului bancar tradițional. În acest articol vom explora cum sistemul Bitcoin se compară cu sistemul bancar.</p>
            <br></br>
<br></br>

<h3 className={classes.subtitle}>Despre rețeaua Bitcoin</h3>
            <ol className={classes.list}>
              
           <li>Tranzacțiile nu pot fi cenzurate sau oprite</li>
              <br></br>
              <li>Fără aplicații specializate tranzacțiile sunt din 10 în 10 minute 24/7</li>

              <br></br>
<li>Taxele de tranzacționare variază și sunt transmite tot de utilizator. Tranzacțiile cu taxa ridicată se confirmă mai rapid.</li>

              <br></br>
              <li>Tu controlezi banii tăi, nu îi ține nimeni pentru tine</li>
            <br></br>
              <li>Fondurile exista 1 la 1 în portofel și sunt verificabile</li>
              <br></br>
              <li>Maximul de monede bitcoin este 21 000 000. Odată atins, nu se vor mai produce alți bitcoini.</li>
            </ol>
            <br></br>

<h3 className={classes.subtitle}>Despre sistemului bancar</h3>
<ol className={classes.list}>

<li>Tranzacțiile pot fi oprite de către bancă</li>
<br></br>
<li>Tranzacțiile interne sunt rapide și fără aplicații specializate</li>

<br></br>
<li>Banca ține banii pentru tine</li>

<br></br>
<li>Fondurile NU există 1 la 1</li>

<br></br>
<li>Tranzacțiile internaționale nu sunt instante fără plată la POS</li>

<br></br>
<li>Nu există un maxim de monede. Fiecare stat scoate câte bancnote vrea</li>
<br></br>
<li>Băncile pot considera țări că ar fi cu risc ridicat și să blocheze tranzacții, sau chiar să excludă țări de la plăți internaționale. Exemple generale sunt Syria, Cuba, Iran, Cuba, Rusia, etc.</li>
</ol>
            <br></br>

            <h3 className={classes.subtitle}>Preia înapoi controlul!</h3>
            <p className={classes.paragraph}>Folosind Bitcoin ca metodă de plată folosești cel mai solid sistem de tranzacționare care te pune înapoi în controlul banilor tăi. Nimeni nu îți poate dicta cui și unde trimiți banii.</p>
<br></br>
            <h3 className={classes.subtitle}> Unde pot citi mai multe despre Bitcoin?</h3>
            <p className={classes.paragraph}> Îți recomandăm să încerci <Link to='/blog' style={{ color: '#87CEEB' }}>celelalte articole</Link> sau chiar <a style={{color: '#87CEEB'}} href='https://ro.wikipedia.org/wiki/Bitcoin'>Wikipedia.</a>
            </p>

            <p className={classes.paragraph}>

              <br />
              <br />
              Noi, Bit Bit, oferim <Link to='/exchange/auth' style={{ color: '#FFA500' }}>structura necesară </Link>
 comercianților pentru a accepta astfel de plăți și ție <Link to='/exchange/auth' style={{ color: '#FFA500' }}>o metodă de a plăti </Link>folosind Bitcoin.
            </p>
          </section>

          <NextPreviousButtons/>

        </article>
       
      </main>
    </div>
  );
}

export default Article3;
