class Transformer {
    codeType() {
      return "TODO";
    }
  
    identified(raw) {
      return false;
    }
  
    async transform(raw) {
      return "TODO";
    }
  
    static buttonCaption() {
      return "TODO";
    }
  }
  
  const CODE_TYPE = {
    UPNQR: "UPNQR",
    COVID19: "COVID",
    RAW: "RAW"
  };
  
  export {Transformer, CODE_TYPE};