import { getAnalytics } from "firebase/analytics";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBOvkg5Vz_PL2m1qdXSPXQKr-0szAEMQOk",
  authDomain: "test-ea26f.firebaseapp.com",
  databaseURL: "https://test-ea26f-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "test-ea26f",
  storageBucket: "test-ea26f.appspot.com",
  messagingSenderId: "493137945790",
  appId: "1:493137945790:web:b9ac97627477cb50906aef",
  measurementId: "G-JQDCJDRHPT"
};

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  export const db = firebase.firestore();
  export default firebaseConfig;