import React from 'react';
import classes from './BlobComponent.module.css';


// const BlobComponent = ({ logoSrc }) => {
const BlobComponent = () => {
  return (
    <div>
      <div className={classes.blob}>
        {/* <img src={logoSrc} alt="Logo" /> */}
      </div>
    </div>
  );
}

export default BlobComponent;