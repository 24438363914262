import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebaseConfig from '../components/Auth/FirebaseConfig';
import '../components/StartingPage/Custom.css'
import classes from '../components/StartingPage/StartingPageContent.module.css';

const ReferralComponent = () => {
  firebase.initializeApp(firebaseConfig);
  const db = firebase.firestore();

  const [currentUser, setCurrentUser] = useState(null);
  const referralInputRef = useRef(null);

  useEffect(() => {
    // Check if a user is currently logged in
    const auth = firebase.auth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleReferral = async () => {
    const referralCode = referralInputRef.current.value.trim();
    // console.log('Referral Code:', referralCode);
  
    if (referralCode) {
      try {
        // Find the user with the referral code in Firestore
        // console.log('Attempting to query Firestore');
        const userDoc = await db.collection('users').doc(currentUser.uid).get();
        const currentUserReferralCode = userDoc.data().referralCode;
  
        const querySnapshot = await db.collection('users').where('referralCode', '==', referralCode).get();
        // console.log('Firestore Query Result:', querySnapshot.docs.length, 'documents found');
  
        if (referralCode === currentUserReferralCode) {
          alert('You cannot use your own referral code.');
          return;
        }

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            // console.log('User Document ID:', doc.id);
            // console.log('Referral Count:', doc.data().referralCount);
          });
  
          const referringUserDoc = querySnapshot.docs[0];
          const referringUserId = referringUserDoc.id;
  
          // Check if the user has already used a referral code
          const userDoc = await db.collection('users').doc(currentUser.uid).get();
          const hasUsedReferralCode = userDoc.data().hasUsedReferralCode;
  
          if (!hasUsedReferralCode) {
            // Update the referring user's referral count
            const referralCountField = db.collection('users').doc(referringUserId).update({
              referralCount: firebase.firestore.FieldValue.increment(1)
            });
  
            // Mark the current user as having used a referral code
            await db.collection('users').doc(currentUser.uid).update({
              hasUsedReferralCode: true
            });
  
            // Clear the input field after successful referral
            referralInputRef.current.value = '';
  
            alert('Referral handled successfully.');
          } else {
            alert('User has already used a referral code.');
          }
        } else {
          alert('Referral code not found. Check for mispells');
        }
      } catch (error) {
        console.error('Error handling referral:', error);
      }
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    handleReferral();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={classes.referral }>
        <label htmlFor="referral" style={{color: "white", fontSize: 22, fontWeight: 550, marginRight:'20px'}}>Referral Code</label>
        <input type="text" id="referral" required ref={referralInputRef} />
      
        <button className="button" style={{width: "200px", height: "20px"}} type="submit">Submit</button>

     </div> </form>
    </div>
  );
};

export default ReferralComponent;
