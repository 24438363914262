import React, { useMemo, useRef, useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvent } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// deocamdata tb puse manual 1 cate 1, va tb facuta varianta cu array si randare prin DOM https://stackoverflow.com/questions/52620309/constructing-a-lot-of-markers-on-react-leaflet-map-is-too-slow
// tb puse iconite specifice in css-ul de la leaflet pt cafenele/burgeri/pizza/etc + facut variabila care sa le acceseze
// tb vazut de ce la markere <popup> NUME </popup> nu apare la fiecare marker individual NUMELE sau
// in viitor in functie de locatia IP sa te puna harta in orasul tau.
const position = [45.5355467, 25.0003577]; //pt harta

// Cand vrei sa adaugi marker nou trebuie: 
// 1. faci const positionMarkerNUMELETAU

// 2. in const RenderIcons faci   
// const markerRefNUMELETAU = useRef();
// const eventHandlersNUMELETAU = useEventHandlers(markerRefNUMELETAU);

// 3. in return pui:
{/* <Marker
ref={markerRefNUMELETAU}
position={positionMarkerNUMELETAU}
icon={icon}
eventHandlers={eventHandlersNUMELETAU}
>
<Popup>NUMELE LOCATIEI</Popup>
</Marker> */}

// 4. in const Map trebuie adaugat in markers
//  { position: positionMarkerNUMELETAU, name: "NUMELE TAU" }

const positionMarkerCarnivore = [44.317943413310054, 23.799285216130894];
const positionMarkerIberico = [44.31735878250526, 23.79868313015447];
const positionMarkerCluj = [46.77061422880211, 23.583312607013042];
const positionMarkerBuc = [44.43495101478797, 26.085743307125405];

const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});


// Aici incep Markerele pentru harta

const useEventHandlers = (markerRef) => {
  return useMemo(
    () => ({
      mouseover() {
        
        if (markerRef.current) markerRef.current.openPopup();
      },
      mouseout() {
      
        if (markerRef.current) markerRef.current.closePopup();
      }
    }),
    [markerRef]
  );
};

const RenderIcons = () => {
  const markerRefCarnivore = useRef();
  const markerRefIberico = useRef();
  const markerRefCluj = useRef();
  const markerRefBuc = useRef();

  const eventHandlersCarnivore = useEventHandlers(markerRefCarnivore);
  const eventHandlersIberico = useEventHandlers(markerRefIberico);
  const eventHandlersCluj = useEventHandlers(markerRefCluj);
  const eventHandlersBuc = useEventHandlers(markerRefBuc);

  return (
    <div>
      <Marker
        ref={markerRefCarnivore}
        position={positionMarkerCarnivore}
        icon={icon}
        eventHandlers={eventHandlersCarnivore}
      >
        <Popup>Test1</Popup>
      </Marker>

      <Marker
        ref={markerRefIberico}
        position={positionMarkerIberico}
        icon={icon}
        eventHandlers={eventHandlersIberico}
      >
        <Popup>Test2</Popup>
      </Marker>

      <Marker
        ref={markerRefCluj}
        position={positionMarkerCluj}
        icon={icon}
        eventHandlers={eventHandlersCluj}
      >
        <Popup>Test3</Popup>
      </Marker>

      <Marker
        ref={markerRefBuc}
        position={positionMarkerBuc}
        icon={icon}
        eventHandlers={eventHandlersBuc}
      >
        <Popup>Test4</Popup>
      </Marker>
    </div>
  );
};

// Aici se termina Markerele pt harta

const LocationMarker = () => {
  const map = useMap();

  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      map.flyTo(e.latlng, 15);
    });
  }, [map]);

  return null;
};
const NearestMarkerButton = ({ markers, currentPosition }) => {
  const map = useMap();
  const [nearestMarker, setNearestMarker] = useState(null);

  const findNearestMarker = () => {
    const nearest = markers.reduce((nearest, marker) => {
      const distance = map.distance(map.getCenter(), marker.position);
      if (!nearest || distance < nearest.distance) {
        return { marker, distance };
      }
      return nearest;
    }, null);

    if (nearest) {
      setNearestMarker(nearest.marker);
      map.flyTo(nearest.marker.position, 15);
    }
  };

 
  const resetMap = () => {
    if (currentPosition) {
      map.flyTo(currentPosition, 15);
    } else {
      map.flyTo(position, 6);
    }
    setNearestMarker(null);
  };



  useEffect(() => {
    setNearestMarker(null);
  }, [map]);

  return (
    <div className="leaflet-top leaflet-right">
      <button className="leaflet-control leaflet-bar buttonMap" onClick={findNearestMarker}>
        Caută cea mai apropiată locație
      </button>
    
      <button className="leaflet-control leaflet-bar buttonMap" onClick={resetMap}>
        Resetează harta
      </button>
    </div>
  );
};


const Map = ({ width = '60vw', height = '60vh' }) => {
  const [currentPosition, setCurrentPosition] = useState(null);

  const markers = [
    { position: positionMarkerCarnivore, name: "Carnivore Burger" },
    { position: positionMarkerIberico, name: "Iberico Drinks" },
    { position: positionMarkerCluj, name: "Cluj Coffee" },
    { position: positionMarkerBuc, name: "MaCa Coffee" }
  ];

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentPosition([position.coords.latitude, position.coords.longitude]);
        },
        (error) => {
          setCurrentPosition(null);
        }
      );
    }
  }, []);
  
  return (
    <MapContainer
     center={currentPosition || position} 
     zoom={currentPosition ? 15 : 6} 
     style={{ height: height, width: width }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <RenderIcons />
      <NearestMarkerButton 
      markers={markers}
      currentPosition={currentPosition}
       />
      <LocationMarker />
    </MapContainer>
  );
};

export default Map;


// aici se afla cod legat de google map api, e cam vai de el

// import React, { useMemo, useRef } from "react";
// import { TileLayer, Marker, Popup } from "react-leaflet";
// import { MapContainer } from 'react-leaflet/MapContainer'
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";

// const position = [51.505, -0.09];

// const icon = L.icon({
//   iconSize: [25, 41],
//   iconAnchor: [10, 41],
//   popupAnchor: [2, -40],
//   iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
//   shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
// });

// const RenderIcons = () => {
//   const markerRef = useRef();

//   const eventHandlers = useMemo(
//     () => ({
//       mouseover() {
//         console.log("over");
//         if (markerRef) markerRef.current.openPopup();
//       },
//       mouseout() {
//         console.log("out");
//         if (markerRef) markerRef.current.closePopup();
//       }
//     }),
//     []
//   );

//   return (
//     <Marker
//       ref={markerRef}
//       position={position}
//       icon={icon}
//       eventHandlers={eventHandlers}
//     >
//       <Popup>Hello</Popup>
//     </Marker>
//   );
// };

// const Map = () => {
//   return (
//     <MapContainer center={position} zoom={13} style={{ height: "100vh" }}>
//       <TileLayer
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//       />
//       <RenderIcons />
//     </MapContainer>
//   );
// };

// export default Map;



// import React from 'react'
// import { Autocomplete, GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const containerStyle = {
//   width: '800px',
//   height: '400px',
  
// };

// const center = {
//   lat: 45.5355467,
//   lng: 25.0003577
  
// };

// function MyMap() {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyC6S554HuViGvHFD0m5YsEX2_-C-RTTtPo"
//   })

//   const [map, setMap] = React.useState(null)

//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);
//     setMap(map)
//   }, [])

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   return isLoaded ? (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={7}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
        
//       >
//         { /* Child components, such as markers, info windows, etc. */ }
//         <></>
//       </GoogleMap>
//   ) : <></>
// }

// export default React.memo(MyMap)