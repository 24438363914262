import callAPI from '../exchange/utils.js';
import React, { useState, useEffect } from "react";
import Plotly from 'plotly.js-dist-min';
import classesExchange from '../exchange/ExchangeElements.module.css';

function TrackingBTC() {
const [latestPriceBTC, setLatestPriceBTC] = useState(0);

	useEffect(() => {
		fetchData().then((chartData) => {
			initChart(chartData);
			setLatestPriceBTC(parseFloat(chartData.price[chartData.price.length - 1]).toFixed(2));
		});
	}, []);

	const fetchData = async () => {
		let data = { index: [], price: [], volumes: [] };
		let result = await callAPI("https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=1&interval=10m");
		for (const item of result.prices) {
			data.index.push(item[0]);
			data.price.push(item[1]);
		}
		for (const item of result.total_volumes) data.volumes.push(item[1]);
		return data;
	};

	const initChart = (data) => {
		let trace_price = {
			name: "Price ($)",
			x: data.index.map((t) => new Date(t)),
			y: data.price,
			xaxis: "x",
			yaxis: "y1",
			type: "scatter",
			mode: "lines+markers",
			marker: { color: "orange", size: 3 },
		};
		// let trace_volumes = {
		// 	name: "Volumne ($B)",
		// 	x: data.index.map((t) => new Date(t)),
		// 	y: data.volumes,
		// 	xaxis: "x",
		// 	yaxis: "y2",
		// 	type: "bar",
		// 	barmode: "relative",
		// 	marker: {
		// 		color: "rgb(49,130,189)",
		// 		opacity: 0.7,
		// 	},
		// };
		let layout = {
			autosize: true,
			height: "100%",
			margin: {
				l: 50,
				r: 100,
				t: 35,
				pad: 3,
			},
			showlegend: false,
			xaxis: {
				domain: [1, 1],
				anchor: "y2",
			},
			yaxis: {
				domain: [0.1, 1],
				anchor: "x",
			},
			yaxis2: {
				showticklabels: false,
				domain: [0, 0.1],
				anchor: "x",
			},
			grid: {
				roworder: "bottom to top",
			},
		};
		let config = { responsive: true };
		let series = [trace_price]; // ,trace_volume
		Plotly.newPlot("chart", series, layout, config);
	};

	return (
		<>
		<section className={classesExchange.tabel}>
			<h2 className='text-center text-primary' style={{color:'orange'}}>Preț Bitcoin {latestPriceBTC}$</h2>
			<br />
			<section className={classesExchange.margins}>
				<div id='chart' className='p-0 m-0'></div>
    </section></section>
		</>
	);
}

export default TrackingBTC;