import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebaseConfig from '../Auth/FirebaseConfig';
import classes from './ProfileForm.module.css';
import AuthContext from '../../store/auth-context';
import ReferralComponent from '../../store/ReferralSystem';

const ProfileForm = () => {
  const [usernameCustom, setUsernameCustom] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isUsernameSet, setIsUsernameSet] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [referralCount, setReferralCount] = useState(null);

  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.logout();
  };

  const resetPasswordHandler = async () => {
    const user = firebase.auth().currentUser;
    try {
      await firebase.auth().sendPasswordResetEmail(user.email);
      alert('Password reset email has been sent. Please check your inbox.');
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    // Initialize Firebase only once
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    // Set Firebase authentication persistence to 'LOCAL'
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    const getUserProperties = async (uid) => {
      try {
        const userSnapshot = await firebase.firestore().collection('users').doc(uid).get();

        if (userSnapshot.exists) {
          const userData = userSnapshot.data();
          setUserData(userData);

          const referralCode = userData.referralCode;
          if (referralCode) 
            setReferralCode(referralCode);

          const userReferralCount = userData.referralCount;
          if (userReferralCount !== undefined) {
            setReferralCount(userReferralCount);
          }
        }
      } catch (error) {
        alert(error.message);
      }
    };

    const fetchUsername = async (user) => {
      try {
        if (user) {
          const userDoc = await firebase.firestore().collection('users').doc(user.uid).get();
          const email = user.email;
          const indexOfAtSymbol = email.indexOf('@');
          const initialUsername = indexOfAtSymbol !== -1 ? email.substring(0, indexOfAtSymbol) : '';

          if (userDoc.exists) {
            const userData = userDoc.data();
            const finalUsername = userData.usernameCustom || initialUsername;

            if (!userData.usernameCustom && initialUsername) {
              await firebase.firestore().collection('users').doc(user.uid).update({
                usernameCustom: initialUsername,
              });
            }

            setUsernameCustom(finalUsername);

            if (!isUsernameSet && !user.displayName && userData.usernameCustom) {
              await user.updateProfile({
                displayName: userData.usernameCustom,
              });

              setIsUsernameSet(true);
            }
          } else {
            setUsernameCustom(initialUsername);

            if (initialUsername) {
              await firebase.firestore().collection('users').doc(user.uid).set({
                usernameCustom: initialUsername,
              }, { merge: true });
            }
          }
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    const user = firebase.auth().currentUser;
    if (user) {
      const userId = user.uid;
      getUserProperties(userId);
      fetchUsername(user);
    }
  }, [isUsernameSet]);

  const handleUsernameChange = (event) => {
    setUsernameCustom(event.target.value);
  };

  const handleEditUsername = () => {
    setIsEditing(true);
  };

  const saveUsername = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const userId = user.uid;
        const normalizedUsername = usernameCustom.replace(/\s/g, '').toLowerCase();

        const existingUsers = await firebase.firestore()
          .collection('users')
          .where('normalizedUsername', '==', normalizedUsername)
          .get();

        if (existingUsers.size === 0) {
          await firebase.firestore().collection('users').doc(userId).set({
            usernameCustom,
            normalizedUsername,
          }, { merge: true });

          await user.updateProfile({
            displayName: usernameCustom,
          });

          setIsEditing(false);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        } else {
          alert('Username is already taken. Please choose a different one.');
        }
      } else {
        alert('No user is currently logged in.');
      }
    } catch (error) {
      console.error('Error saving username:', error);
      alert('Error saving username. Please try again later.');
    }
  };

  return (
    <div>
      {userData && (
        <div style={{ color: 'white' }}>
          <h2>Profile Information</h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '20px' }}>
            {!isEditing ? (
              <>
                <strong>Username: {usernameCustom}</strong>
                <span style={{ marginRight: '5px', marginLeft: '5px', color: 'yellow', cursor: 'pointer' }} onClick={() => handleEditUsername()}>
                  ✏️
                </span>
              </>
            ) : (
              <>
                <input
                  type="text"
                  value={usernameCustom}
                  onChange={handleUsernameChange}
                  placeholder="Enter your username"
                  style={{ border: 'none', outline: 'none', backgroundColor: 'transparent', color: 'white', fontSize: '16px', cursor: 'pointer' }}
                />
                <span style={{ marginLeft: '5px', color: 'yellow', cursor: 'pointer' }} onClick={saveUsername}>
                  💾
                </span>
              </>
            )}
            {showSuccessMessage && <div style={{ marginLeft: '10px' }}>Username updated successfully!</div>}
          </div>
          <div><strong>Transactions: </strong> {userData.transactions}</div>
          <div>
            <strong>KYC:</strong> {userData.KYC ? 'Verified' : 'Not Verified'}
          </div>
          <div> <strong>Your referral code is: </strong>{referralCode}</div>
          <div>
            <strong>Referral Count: </strong>{referralCount}
          </div>
        </div>
      )}
      <div className={classes.control}>
        <div className={classes.action}>
          <h2 style={{ color: "white" }}>Forgot your password?</h2>
          <button
            className="button"
            onClick={resetPasswordHandler}
            style={{ color: 'black', fontWeight: 'bold', height: '50px', width: '200px' }}
          >
            Change Password
          </button>
        </div>
      </div>
      <ReferralComponent />
      <div className={classes.action}>
        <button className="button" style={{ fontWeight: 'bold', height: "75px", width: "200px" }} onClick={logoutHandler}>Logout</button>
      </div>
    </div>
  );
};

export default ProfileForm;
