import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import classes from "./GoogleLogin.module.css";

const GoogleLoginButton = () => {
  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const userCredential = await firebase.auth().signInWithPopup(provider);

      // Check if the user exists in Firebase database
      const user = await checkIfUserExists(userCredential.user.uid);

      // If the user doesn't exist, create a new account 
      if (!user) {
        // Get the relevant information from the Google user
        const { displayName, email, uid } = userCredential.user;
        
        // Create a new user entry in the database
        await createNewUser(uid, displayName, email);
      }

      // Handle successful login
      console.log('Logged in with Google:', userCredential.user);
    } catch (error) {
      // Handle login error
      console.error('Google login error:', error);
    }
  };

  const checkIfUserExists = async (uid) => {
    // Implement the logic to check if a user with the provided UID exists in your Firebase database
    // You can use Firebase Firestore or Realtime Database for this
    // Return the user data if exists, otherwise return null
    // Example using Firestore:
    const userDoc = await firebase.firestore().collection('users').doc(uid).get();
    return userDoc.exists ? userDoc.data() : null;
  };

  const createNewUser = async (userId, displayName, enteredEmail) => {
    // Implement the logic to create a new user entry in your Firebase database
    // This will be similar to how you handle the registration of a normal account
    // You can use Firebase Firestore or Realtime Database for this
    // Example using Firestore:
    await firebase.firestore().collection('users').doc(userId).set({
      username: displayName,
      email: enteredEmail,
      balance: 0,
      KYC: false, // Set to false by default for Google login (assuming KYC is not done during Google login)
      transactions: 0
    });
  };

  return (
    <button className={classes.icon} onClick={handleGoogleLogin}></button>
  );
};

export default GoogleLoginButton;
