import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classes from '../Wallet/Wallet.module.css';

const SuccessModal = ({ isOpen, onClose, customText }) => {
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    if (isOpen) {

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
 
        onClose();
      }, 8000); 
    }
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className={classes.modal}>
          <div className={classes.modalContent}>
            <button className={classes.closeButton} onClick={onClose}>
              x
            </button>
            <p>{customText}</p>
          </div>
        </div>
      )}
    </>
  );
};

SuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customText: PropTypes.string,
};

export default SuccessModal;
