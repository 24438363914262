import React, { useEffect, useState } from 'react';
import ProfileForm from './ProfileForm';
import classes from './UserProfile.module.css';

const UserProfile = () => {


  return (
    <section className={classes.profile}>
      <h1>Your User Profile</h1>
   <ProfileForm/>
    </section>
  );
};

export default UserProfile;